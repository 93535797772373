import { useContext, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
} from '@material-ui/core';
// import makeStyles from '@material-ui/styles'
import NavItem from './NavItem';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FaceIcon from '@mui/icons-material/Face';
import ReorderIcon from '@mui/icons-material/Reorder';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CodeIcon from '@mui/icons-material/Code';
import SettingsIcon from '@mui/icons-material/Settings';
import PostAddIcon from '@mui/icons-material/PostAdd';
import StoreIcon from '@mui/icons-material/Store';
import { Button } from '@mui/material';
import { GlobalContext } from 'src/context/store';
import { Auth } from 'aws-amplify';
import { userLogout, initStatusManager } from 'src/context/actions';
import makeStyles from '@material-ui/styles/makeStyles';
import './Layouts/Sidebar.css';

const useStyles = makeStyles({
  MenuInner: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: 'grey',
    padding: '0',
    fontSize: '0.8em',
    marginTop: '0',
    textAlign: 'left',
    marginBottom: '0',
    textTransform: 'uppercase',
  },
  title: {
    color: '#263238',
    fontSize: '16px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '-0.06px',
    textTransform: 'uppercase',
    marginTop: '5px',
  },
  subtitle: {
    color: '#546e7a',
    fontSize: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: '-0.04px'
  },
  sign_btn_1: {
    color: 'rgba(0, 0, 0, 0.87) !important',
    padding: '6px 16px',
    fontSize: '14px',
    minWidth: '64px',
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    border: 'none',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '500',
    lineHeight: '1.75',
    borderRadius: '4px',
    letterSpacing: '0.02857em',
    textTransform: 'none',
  },
});

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'adriano.teixeira@didimo.co',
  name: 'Katarina Smith',
};

const items = [
  {
    href: `${process.env.REACT_APP_ROOT_PATH}/welcome`,
    icon: EmojiPeopleIcon,
    title: 'Welcome',
  },
  {
    href: `${process.env.REACT_APP_ROOT_PATH}/dashboard`,
    icon: DashboardIcon,
    title: 'Dashboard',
  },
  {
    href: `${process.env.REACT_APP_ROOT_PATH}/mydidimos`,
    icon: FaceIcon,
    title: 'My didimos',
  },
  {
    href: 'developers/market-place',
    icon: StoreIcon,
    title: 'Marketplace',
  },
];

const items2 = [
  {
    href: `${process.env.REACT_APP_ROOT_PATH}/transactions`,
    icon: ReorderIcon,
    title: 'Transactions',
  },
  {
    href: `${process.env.REACT_APP_ROOT_PATH}/packages`,
    icon: ShoppingBasketIcon,
    title: 'Packages',
    function: true,
  },
  {
    href: `${process.env.REACT_APP_ROOT_PATH}/orders`,

    icon: AttachMoneyIcon,
    title: 'Orders',
  },
  {
    href: `${process.env.REACT_APP_ROOT_PATH}/profile/settings`,
    icon: SettingsIcon,
    title: 'Settings',
  },
];
const items3 = [
  {
    href: 'developers/applications',
    icon: CodeIcon,
    title: 'Applications',
  },
  {
    href: 'templates',
    icon: PostAddIcon,
    title: 'Templates',
  },
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const fullName = state?.user?.given_name + ' ' + state?.user?.family_name;
  const avtarName = fullName.split(' ');
  const marketPlaceData = state?.marketPlaceData?.length;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const getItem = (title) => {
    if (title === 'Marketplace') {
      if (marketPlaceData > 0) {
        return true;
      } else {
        return false;
      }
    } else if (title === 'Packages') {
      return process.env.REACT_APP_DISPLAY_PACKAGES==="true"
    } else {
      return true;
    }
  };

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        p: 2,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Avatar
          component={RouterLink}
          // src={user.avatar}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64,
          }}
          to="/app/account"
          style={{ textTransform: 'uppercase', marginBottom: '10px' }}
        >
          {avtarName[0] !== 'undefined' &&
            avtarName[0].charAt(0) + avtarName[1].charAt(0)}
        </Avatar>

        <Typography
          className={classes.subtitle}
          color="textSecondary"
          variant="body2"
          style={{ textAlign:"center" }}
        >
          {state?.user?.email}
        </Typography>
        <Button
          onClick={() => {
            Auth.signOut();
            dispatch(initStatusManager([]));
            dispatch(userLogout());
            navigate(`${process.env.REACT_APP_ROOT_PATH}/login`, {
              replace: true,
            });
          }}
          className={classes.sign_btn_1}
        >
          {' '}
          Sign Out
        </Button>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box>
        <List>
          {items.map((item) => (
            <>
              {getItem(item.title) && (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              )}
            </>
          ))}
          {/*<Hidden mdUp>
            <NavItem
              href={'#'}
              key={'notifications'}
              title={'Notifications'}
              icon={NotificationsIcon}
            />
          </Hidden>*/}
        </List>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box>
        <h1 className={classes.MenuInner}>ACCOUNT</h1>
        <List>
          {items2.map((item) => (
            <>
            {getItem(item.title) && (<NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              fn={item.function}
            />)}
          </>
          ))}
        </List>
      </Box>
      <Divider sx={{ my: 2 }} />
      {state?.isDeveloper === true && (
        <Box>
          <h1 className={classes.MenuInner}> DEVELOPERS</h1>
          <List>
            {items3.map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ))}
          </List>
        </Box>
      )}
    </Box>
  );

  return (
    <div className={'dashSidebar'}>
      <Hidden xlUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
              backgroundColor: '#eeeeee',
              top: '64px',
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)',
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </div>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
