import {
  Backdrop,
  Button,
  CircularProgress,
  Typography,
  TableContainer,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import queryString from 'query-string';
import { getData } from 'src/helpers/dataFetching';
import React, { useState, useEffect, Fragment, useContext } from 'react';
import { useNavigate, Navigate, Link } from 'react-router-dom';
import makeStyles from '@material-ui/styles/makeStyles';
import { GlobalContext } from 'src/context/store';
import { v4 } from 'uuid';
import CookieBase from 'src/components/CookieBase';
import { Box } from '@mui/material';
import ErrorSnack from '../../../components/ErrorSnack';
import PackageDetailsTable from '../PackageDetailsTable/PackageDetailsTable';
import { services, packages } from '../PackageData/PackageData';
import { formatCurrency, formatPoints } from '../../../helpers/formaters';
import TierIcon from '../../../components/TierIcon';
import ContactUs from '../../../components/ContactUs';
import appConfig from '../../../components/AppConfig';
import { Alert } from '@mui/lab';
import { isUserAuthenticated } from '../../../context/actions';

// makeStyles((theme) => ({

const PACKAGE_OPACITY = 0.3;
const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  applicationDetail: {
    flexDirection: 'column',
  },
  deleteButton: {
    backgroundColor: 'red',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  backdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  icon: {
    height: 96,
    width: 96,
    objectFit: 'scale-down',
  },
  trialHeader: {
    backgroundColor: theme.packages.trial.mainColor,
  },
  basicHeader: {
    backgroundColor: theme.packages.basic.mainColor,
  },
  starterHeader: {
    backgroundColor: theme.packages.basic.mainColor,
  },
  advancedHeader: {
    backgroundColor: theme.packages.advanced.mainColor,
  },
  proHeader: {
    backgroundColor: theme.packages.pro.mainColor,
  },
  enterpriseHeader: {
    backgroundColor: theme.packages.enterprise.mainColor,
  },
  trial: {
    backgroundColor: theme.packages.trial.mainOpaqueColor(PACKAGE_OPACITY),
  },
  basic: {
    backgroundColor: theme.packages.basic.mainOpaqueColor(PACKAGE_OPACITY),
  },
  starter: {
    backgroundColor: theme.packages.basic.mainOpaqueColor(PACKAGE_OPACITY),
  },
  advanced: {
    backgroundColor: theme.packages.advanced.mainOpaqueColor(PACKAGE_OPACITY),
  },
  pro: {
    backgroundColor: theme.packages.pro.mainOpaqueColor(PACKAGE_OPACITY),
  },
  enterprise: {
    backgroundColor: theme.packages.enterprise.mainOpaqueColor(PACKAGE_OPACITY),
  },
  checkedFeature: {
    color: green[600],
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  points: {
    backgroundColor: 'rgba(0,0,0,.08)',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '15px',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    fontSize: '1em',
    '@media only screen and (max-width: 660px)': {
      borderRadius: '0',
      fontSize: '0.8em',
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
  headerCell: {
    borderBottom: 0,
    backgroundColor: 'white',
  },
  packagesRoot: {
    display: 'grid',
    gridTemplateColumns: '300px repeat(4, minmax(0, 1fr))',
    '& > div': {
      padding: '16px',
      borderTop: '1px solid #eeeeee',
      borderBottom: '1px solid #eeeeee',
      verticalAlign: 'middle',
      fontSize: '14px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      lineHeight: '21px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '@media only screen and (max-width: 1024px)': {
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
      '& > div': {
        borderTop: '0',
        borderBottom: '0',
        fontSize: '13px',
      },
    },
    '@media only screen and (max-width: 660px)': {
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
      '& > div': {
        padding: '10px',
      },
    },
  },
  packageHeader: {
    textAlign: 'center',
    position: 'relative',
    '&[data-package-index="0"]': {
      gridColumn: '2',
    },
    '@media only screen and (max-width: 1024px)': {
      '&[data-package-index="0"]': {
        gridColumn: '1',
      },
    },
    '@media only screen and (max-width: 660px)': {
      '&:not(.selected)': {
        '& p': {
          fontSize: '12px',
          lineHeight: '12px',
        },
        '& img': {
          width: '48px',
          height: '48px',
        },
        padding: '8px',
        overflow: 'hidden',
      },
    },
    '@media only screen and (max-width: 320px)': {
      '&:not(.selected)': {
        '& p': {
          fontSize: '10px',
        },
      },
    },
  },
  attributeName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left !important',
    '@media only screen and (max-width: 1024px)': {
      gridColumn: '1/5',
      padding: '10px 10px 4px 10px !important',
      justifyContent: 'center !important',
      '& p': {
        fontWeight: 'bold',
        fontSize: '12px',
      },
      '& div': {
        textAlign: 'center',
      },
    },
  },
  attributeValue: {
    '@media only screen and (max-width: 660px)': {
      '&:not(.selected)': {
        /* display: "none !important", */
      },
    },
  },
  areaName: {
    gridColumn: '1/6',
    textAlign: 'center',
    fontWeight: 'bold',
    '@media only screen and (max-width: 1024px)': {
      gridColumn: '1/5',
      borderTop: '1px solid #eeeeee !important',
      marginTop: '16px !important',
      marginBottom: '8px !important',
      paddingTop: '8px !important',
      paddingBottom: '0 !important',
    },
  },
}));

const CheckedIcon = withStyles(styles)((props) => (
  <CheckCircleIcon className={props.classes.checkedFeature} />
));

const PackageDetails = (props) => {
  const [packagesLoaded, setPackagesLoaded] = useState(false);
  const [bucketData, setBucketData] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [productsLoaded, setProductsLoaded] = useState(false);
  const [products, setProducts] = useState([]);
  const [isBuying, setIsBuying] = useState(false);
  const [missingPolicies, setMissingPolicies] = useState([]);
  const [policyDecisions, setPolicyDecisions] = useState([]);
  const [markMissingPolicies, setMarkMissingPolicies] = useState(false);
  const [isMissingPoliciesMessage, setIsMissingPoliciesMessage] = useState(
    false
  );
  const [redirectToCheckout, setRedirectToCheckout] = useState(null);
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  const [successMessageOpen, setSuccessMessageOpen] = useState(false);
  const [contactUsOpen, setContactUsOpen] = useState(false);
  const [statusData, setStatusData] = useState();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);

  const classes = styles();

  useEffect(() => {
    setLoadingProducts(true);
    (async () => {
      const data = await getData('services/payments/stripe/productList');
      setLoadingProducts(true);
      setProductsLoaded(true);
      setProducts(data);

      const accountStatus = await getData(
        `accounts/${state.user.accounts?.uuid}/status`
      );
      setStatusData(accountStatus);
    })();
    (async () => {
      try {
        if (state.isAuth === false) {
          dispatch(await isUserAuthenticated(navigate, 'packages/details'));
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setContactUsOpen(true);
  };

  // const handleClose = () => {
  //   this.setState({
  //     contactUsOpen: false,
  //   });
  // };

  const setIsBuyingProduct = (value) => {
    this.setState({
      isBuying: value,
    });
  };

  // const setMarkMissingPolicies = (value) => {
  //   this.setState({
  //     markMissingPolicies: value,
  //   });
  // };

  // const setIsMissingPoliciesMessage = (value) => {
  //   this.setState({
  //     isMissingPoliciesMessage: value,
  //   });
  // };
  const handleOnPoliciesSubmit = async () => {
    const response = await getData(
      `${process.env.REACT_APP_API_ROOT}accounts/${state.user.uuid}`
    );
    console.log('HANDLEONPOLICISSUBMIT', response);
    let policyDecisions = {};

    const newMissingPolicies = response.policies.filter(
      (policyMissing) =>
        (policyMissing.status == null) &
        (policyMissing.data_policy.target_users.length === 1) &
        (policyMissing.data_policy.target_users[0] === 'customers')
    );

    policyDecisions = response.policies;

    setPolicyDecisions(policyDecisions);
    setMissingPolicies(newMissingPolicies);
    return newMissingPolicies;
  };

  const removeFromHash = (keys) => {
    const hashItems = queryString.parse(this.props.location.hash);
    const removableKeys = Array.isArray(keys) ? keys : [keys];

    removableKeys.map((key) => delete hashItems[key]);

    return queryString.stringify(hashItems);
  };

  const handleClose = () => {
    setTimeout(() => {
      navigate.push(
        `#${this.removeFromHash(['selectNewPackage', 'buyPackage'])}`
      );
    }, 100);
  };

  const handleBuyOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const productId = e.currentTarget.getAttribute('data-id');
    if (!isBuying) {
      setIsBuying(true);
      handleOnPoliciesSubmit()
        .then((_missingPolicies) => {
          const payload = {
            uuid: productId,
          };
          if (_missingPolicies.length === 0) {
            this.props
              .postData(
                `${process.env.REACT_APP_API_ROOT}services/payments/stripe/orders`,
                payload,
                props.session
              )
              .then((response) => {
                setIsBuying(false);
                const allPayload = {
                  orderId: response.uuid,
                  paymentIntentId: response.paymentIntentId,
                  ...payload,
                };

                if (allPayload.paymentIntentId) {
                  setRedirectToCheckout(
                    `/orders#checkout=${allPayload.orderId}`
                  );
                } else {
                  setSuccessMessageOpen(true);
                  handleClose();
                }
              })
              .catch((error) => {
                setIsBuying(false);
                setState({ errorMessageOpen: true });
                handleClose();
              });
          } else {
            setMarkMissingPolicies(true);
            setIsBuying(false);
            setIsMissingPoliciesMessage(true);
          }
        })
        .catch((error) => {
          setIsBuying(false);
        });
    }
  };

  if (redirectToCheckout) {
    return <Navigate replace to={redirectToCheckout} />;
  }

  let pageContents = [];
  const noDataArea = {
    textAlign: 'center',
  };
  const noDataText = {
    fontWeight: 'bold',
    fontSize: 20,
  };
  if (productsLoaded) {
    if (products.length === 0) {
      pageContents = (
        <>
          <div style={noDataArea}>
            <ShoppingBasketIcon style={{ fontSize: 200 }} />
            <p style={noDataText}>There are no active packages.</p>
          </div>
        </>
      );
    } else {
      const actionArea = (
        <>
          {packages.map((pckg, index) => (
            <Grid
              key={v4()}
              align="center"
              className={`${classes.packageHeader} ${
                classes[`${pckg.code}Header`]
              }`}
              data-package-index={index}
            >
              {(pckg.action === 'register' && <Typography />) ||
                (pckg.action === 'buy' && (
                  process.env.REACT_APP_ALLOW_BUY==="true" && <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    href={`/orders#checkout=${
                      products.find((f) => f.name === pckg.name).uuid
                    }`}
                  >
                    Buy
                  </Button>
                ))}
            </Grid>
          ))}
        </>
      );

      pageContents = (
        <TableContainer
          style={{
            maxWidth: '1000px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Grid className={classes.packagesRoot}>
            {packages.map((pckg, index) => (
              <Grid
                key={v4()}
                align="center"
                className={`${classes.packageHeader} ${
                  classes[`${pckg.code}Header`]
                }
                `}
                data-package-index={index}
              >
                <div style={{ display: 'block', width: '100%' }}>
                  <TierIcon tierCode={pckg.code} className={classes.icon} />
                  <Typography variant="h4" component="p">
                    {pckg.name}
                  </Typography>
                </div>
              </Grid>
            ))}

            {actionArea}

            {services.map((service) => (
              <Fragment key={v4()}>
                {service.name && (
                  <Grid className={classes.areaName}>{service.name}</Grid>
                )}
                {service.services.map((feature, index) => (
                  <Fragment key={v4()}>
                    <Grid component="div" className={classes.attributeName}>
                      <div>
                        <Typography variant="body1">{feature.name}</Typography>
                        {feature.apiActions &&
                          feature.apiActions.length > 0 &&
                          feature.apiActions.map((apiAction) => (
                            <Typography variant="caption" key={v4()}>
                              API: {apiAction.name}
                            </Typography>
                          ))}
                      </div>
                    </Grid>
                    {packages.map((pckg) => (
                      <Grid
                        align="center"
                        key={v4()}
                        className={`${classes[pckg.code]} ${
                          classes.attributeValue
                        }`}
                      >
                        <div style={{ display: 'block', width: '100%' }}>
                          {(pckg.features[feature.code] &&
                            pckg.features[feature.code].price && (
                              <>
                                {formatCurrency(
                                  pckg.features[feature.code].price,
                                  0
                                )}
                                <Typography variant="body2">
                                  plus taxes
                                </Typography>
                              </>
                            )) ||
                            ''}

                          {(pckg.features[feature.code] &&
                            pckg.features[feature.code].available === true && (
                              <CheckedIcon />
                            )) ||
                            ''}
                          {(pckg.features[feature.code] &&
                            pckg.features[feature.code].text &&
                            pckg.features[feature.code].text) ||
                            ''}
                          {(pckg.features[feature.code] &&
                            pckg.features[feature.code].cost && (
                              <Typography
                                variant="body2"
                                className={classes.points}
                              >
                                {formatPoints(pckg.features[feature.code].cost)}{' '}
                                points
                              </Typography>
                            )) ||
                            ''}
                        </div>
                      </Grid>
                    ))}
                  </Fragment>
                ))}
              </Fragment>
            ))}

            {actionArea}
          </Grid>
        </TableContainer>
      );

      pageContents = (
        <div>
          <PackageDetailsTable
            products={products}
            showAPIInfo
            showRegister={false}
            handleBuyOnClick={handleBuyOnClick}
          />
        </div>
      );
    }
  } else {
    pageContents = '';
  }

  return (
    <Box sx={{ py: 2, px: 2, backgroundColor: '#eeeeee' }}>
      {statusData?.pending_orders.length > 0 && (
        <Alert
          severity="warning"
          style={{
            border: '1px solid #ff9800',
            backgroundColor: 'white',
            marginBottom: '10px',
          }}
        >
          You have pending invoices. Please visit{' '}
          <Link to="/orders">Orders</Link> to proceed with the payment.
        </Alert>
      )}
      <Card>
        <CardContent>
          {pageContents}
          <ContactUs
            open={contactUsOpen}
            onClose={handleClose}
            products={products}
            onSubmit={(contactData) =>
              this.props
                .postData(appConfig.apiUris.postContactUs, {
                  name: contactData.name,
                  email: contactData.email,
                  message: contactData.message,
                })
                .then((response) => {
                  this.setState({ successMessageOpen: true });
                })
                .catch((err) => {
                  this.setState({ errorMessageOpen: true });
                })
            }
          />
          <Backdrop className={classes.backdrop} open={!productsLoaded}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </CardContent>
      </Card>

      <ErrorSnack
        open={errorMessageOpen}
        onClose={(e) => setErrorMessageOpen(false)}
        message="An error occurred while processing your order."
      />

      <Backdrop className={classes.backdrop} open={isBuying}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CookieBase />
    </Box>
  );
};
export default PackageDetails;
