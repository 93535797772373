import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Grid,
  TextField,
  Divider,
  FormControlLabel,
  Checkbox,
  Typography,
  Card,
  CardHeader,
  CardContent,
  InputAdornment,
  Autocomplete,
} from '@material-ui/core';
import EmailField from '../../components/EmailField';
import TimezoneSelect from '../../components/TimezoneSelect';
import CountrySelect from '../../components/CountrySelect';
import USCityField from '../../components/USCityField';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../context/store';
import './Settings.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ConfirmDeleteDialog from '../../components/ConfirmDeleteDialog';
import {
  saveLoginUserData,
  changeDeveloper,
  isUserAuthenticated,
} from 'src/context/actions';
import CookieBase from '../../components/CookieBase';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import SuccessSnack from '../../components/SuccessSnack';
import { Alert } from '@mui/material';
import { getData } from '../../helpers/dataFetching';
import USStatesField from '../../components/USStatesField';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  cardHeaderTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Settings = () => {
  const classes = useStyles();
  const { state, dispatch } = useContext(GlobalContext);

  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [email, setEmail] = useState('');

  const [countrySelectedCode, setcountrySelectedCode] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [countryList, setCountryList] = useState('');
  const [countryError, setCountryError] = useState('');

  const [timezone, setTimezone] = useState(null);
  const [streetAddressLn1, setStreetAddressLn1] = useState('');
  const [streetAddressLn2, setStreetAddressLn2] = useState('');
  const [states, setStates] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [taxId, setTaxId] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountEmailAddress, setAccountEmailAddress] = useState('');
  const [isDeveloper, setIsDeveloper] = useState(false);
  const [isCompanyAccount, setIsCompanyAccount] = useState(false);
  const [successMessageOpen, setSuccessMessageOpen] = useState(false);
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  const [requiredFields, setRequiredFields] = useState([
    'givenName',
    'familyName',
    'country',
    'timezone',
  ]);
  const [isDataFieldsDirty, setIsDataFieldsDirty] = useState(false);
  const [touched, setTouched] = useState({});
  const [deleteAccount, setDeleteAccount] = useState(false);
  const navigate = useNavigate();
  const [isDataLoaded, setisDataLoaded] = useState(false);
  const [isAccount, setIsAccount] = useState(false);

  useEffect(() => {
    if (countryCode != '') {
      axios.get('geo/countries', {}).then((res) => {
        setCountryList(res.data);
        let selectedCountry = res.data.find(
          (country) => country.iso_code2 === countryCode
        ) || { __type: '', iso_code2: '', name: '' };
        setcountrySelectedCode(selectedCountry);
        setisDataLoaded(true);
      });
    }
  }, [countryCode]);

  useEffect(() => {
    axios.get('profiles/me').then((res) => {
      let requiredFields = ['givenName', 'familyName', 'country', 'timezone'];
      if (res.data.accounts[0].address.country === 'US') {
        requiredFields.push('state');
      }
      setGivenName(res.data.given_name);
      setFamilyName(res.data.family_name);
      setEmail(res.data.email_address);
      setCountryCode(res.data.accounts[0].address.country);
      setStreetAddressLn1(res.data.accounts[0].address.street_address_line1);
      setStreetAddressLn2(res.data.accounts[0].address.street_address_line2);
      setStates(res.data.accounts[0].address.state);
      setCity(res.data.accounts[0].address.city);
      setZipCode(res.data.accounts[0].address.zip_code);
      setTimezone(res.data.accounts[0].timezone);
      setTaxId(res.data.accounts[0].tax_id);
      setAccountName(res.data.accounts[0].company_name);
      setAccountEmailAddress(res.data.accounts[0].email_address);
      setIsDeveloper(res.data.accounts[0].is_developer);
      setIsCompanyAccount(res.data.accounts[0].is_company);
      setRequiredFields(requiredFields);
      setIsDataFieldsDirty(false);
    });
    (async () => {
      try {
        if (state.isAuth === false) {
          dispatch(await isUserAuthenticated(navigate, 'settings'));
        }
      } catch (err) {
        console.log(err);
      }
    })();

    try {
      const localItems = { ...localStorage };

      let data = '';
      Object.entries(localItems).map(([key, valueJSON]) => {
        if (key.indexOf('.userData') > -1) {
          let myj = JSON.parse(valueJSON);
          data = myj.UserAttributes.find((f) => f.Name === 'identities');
        }
      });
      setIsAccount(data ? false : true);
    } catch {
      setIsAccount(true);
    }
  }, []);

  const handleSaveData = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let data = [
      {
        op: 'replace',
        path: '/address/street_address_line1',
        value: streetAddressLn1,
      },
      {
        op: 'replace',
        path: '/address/street_address_line2',
        value: streetAddressLn2,
      },
      {
        op: 'replace',
        path: '/address/state',
        value: states,
      },
      {
        op: 'replace',
        path: '/address/city',
        value: city,
      },
      {
        op: 'replace',
        path: '/address/country',
        value: countryCode,
      },
      {
        op: 'replace',
        path: '/address/zip_code',
        value: zipCode,
      },
      {
        op: 'replace',
        path: '/timezone',
        value: timezone,
      },
      {
        op: 'replace',
        path: '/tax_id',
        value: isCompanyAccount ? taxId : '',
      },
      {
        op: 'replace',
        path: '/email_address',
        value: isCompanyAccount ? accountEmailAddress : '',
      },
      /*{
        op: 'replace',
        path: '/is_developer',
        value: isDeveloper,
      },*/
      {
        op: 'replace',
        path: '/is_company',
        value: isCompanyAccount,
      },
      {
        op: 'replace',
        path: '/company_name',
        value: isCompanyAccount ? accountName : '',
      },
    ];

    let payloadNew = {
      family_name: familyName,
      given_name: givenName,
    };

    axios
      .patch('accounts/' + state.user.accounts.uuid, data, {
        headers: { Authorization: `Bearer ${state.user.accessToken}` },
      })
      .then((res) => {
        setSuccessMessageOpen(true);
        dispatch(
          changeDeveloper({
            isDeveloper: res.data.is_developer,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .put('profiles/' + state.user.profile_uuid, payloadNew, {
        headers: { Authorization: `Bearer ${state.user.accessToken}` },
      })
      .then((res) => {
        setIsDataFieldsDirty(false);
        setSuccessMessageOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isFormValid = (field) => {
    let fields = [];
    if (field) {
      fields.push(field);
    } else {
      fields = requiredFields;
      const untouchedFields = Object.assign(
        {},
        ...fields.filter((fld) => !touched[fld]).map((fld) => ({ [fld]: true }))
      );
      setTouched({ ...touched, ...untouchedFields });
    }

    const invalidFields = fields.filter((fld) => {
      return !field || touched[fld];
    });
    return invalidFields.length === 0;
  };

  const deleteAndSignOut = () => {
    axios
      .delete('accounts/' + state.user.accounts.uuid, {
        headers: { Authorization: `Bearer ${state.user.accessToken}` },
      })
      .then((res) => {
        Auth.signOut({ global: true });
        navigate(`${process.env.REACT_APP_ROOT_PATH}/login`);
      })
      .catch((err) => {
        console.log(err);
      });

    /*   Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
      .then((user) => {
        user.deleteUser((error, data) => {
          if (error) {
            throw error;
          }
          // Log the user out
          Auth.signOut({ global: true });
        });
      })
      .then((user) => {
        // setDeleteAccount(false);

        navigate(`${process.env.REACT_APP_ROOT_PATH}/login`);
      })
      .catch((err) => console.log(err));
  } */
  };

  const onCountryChange = (e, selected) => {
    let requiredFields = ['givenName', 'familyName', 'country', 'timezone'];
    if (selected && countryCode !== selected.iso_code2) {
      setCountryCode(selected.iso_code2);
      setStates('');
      setCity('');
      setZipCode('');

      if (selected.iso_code2 === 'US') {
        requiredFields.push('state');
      }
      setRequiredFields(requiredFields);
      setIsDataFieldsDirty(true);
    }
  };

  const onStateChange = (e, selected) => {
    if (selected && states !== selected.code) {
      setStates(selected.code);
      setCity('');
      setZipCode('');
      setIsDataFieldsDirty(true);
    }
  };

  const onCityChange = (e, selected) => {
    if (selected && city !== selected.code) {
      setCity(selected.code);
      setZipCode('');
      setIsDataFieldsDirty(true);
    }
  };
  const [pendingOrders, setDdmoStatus] = useState();

  useEffect(() => {
    getData('accounts/default/status').then((res) => {
      setDdmoStatus(res?.pending_orders.length);
    });
  }, []);

  return (
    <div className={'settingNav'}>
      <Helmet>
        <title>Settings | Didimo</title>
      </Helmet>

      {isDataLoaded === true && (
        <div className={classes.root}>
          {pendingOrders > 0 && (
            <Alert
              severity="warning"
              style={{ border: '1px solid #ff9800', backgroundColor: 'white' }}
            >
              You have pending invoices. Please visit{' '}
              <Link to="/orders">Orders</Link> to proceed with the payment.
            </Alert>
          )}
          <Grid container spacing={2} style={{ marginTop: '0px' }}>
            <Grid item xs={12}>
              <Card style={{ padding: '16px' }}>
                <CardContent style={{ padding: '0px' }} className="user_detail">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="h4"
                        sx={{ fontWeight: 'bold', paddingBottom: '16px' }}
                      >
                        User data
                      </Typography>
                      <CardContent style={{ padding: 0 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              value={givenName}
                              onChange={(e) => {
                                setGivenName(e.target.value);
                                setIsDataFieldsDirty(true);
                              }}
                              required
                              fullWidth
                              id="user-setting-given-name"
                              label="Given name"
                              variant="outlined"
                              error={touched['givenName'] && !givenName?.trim()}
                              helperText={
                                touched['givenName'] &&
                                !givenName?.trim() &&
                                'This field is required'
                              }
                              onBlur={(e) => {
                                setTouched({ ...touched, givenName: true });
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextField
                              value={familyName}
                              onChange={(e) => {
                                setFamilyName(e.target.value);
                                setIsDataFieldsDirty(true);
                              }}
                              required
                              fullWidth
                              id="user-setting-family-name"
                              label="Family name"
                              variant="outlined"
                              error={
                                touched['familyName'] && !familyName?.trim()
                              }
                              helperText={
                                touched['familyName'] &&
                                !familyName?.trim() &&
                                'This field is required'
                              }
                              onBlur={(e) => {
                                setTouched({ ...touched, familyName: true });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <EmailField
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                setIsDataFieldsDirty(true);
                              }}
                              fullWidth
                              label="Email"
                              disabled
                              inputProps={{
                                'aria-label': 'email',
                                'data-id': 'email',
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              id="Country"
                              required
                              options={countryList}
                              getOptionLabel={(option) => `${option?.name}`}
                              onChange={onCountryChange}
                              onInputChange={(e, value, operation) => {
                                if (operation == 'clear') {
                                  onCountryChange(e, { iso_code2: '' });
                                }
                              }}
                              defaultValue={countrySelectedCode}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  helperText={countryError}
                                  label="Country *"
                                  name="countrycode"
                                  variant="outlined"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          {countryCode?.toUpperCase() === 'US' && (
                            <Grid item xs={12}>
                              <USStatesField
                                required
                                fullWidth
                                onChange={onStateChange}
                                value={states}
                                /*error={
                                  this.state.touched["state"] &&
                                  !this.state.userDataFields.state.trim()
                                }
                                helperText={
                                  this.state.touched["state"] &&
                                  !this.state.userDataFields.state.trim() &&
                                  "This field is required"
                                }
                                onBlur={(e) => {
                                  this.setState({
                                    touched: {
                                      ...this.state.touched,
                                      state: true,
                                    },
                                  });
                                }}*/
                                //disabled={!this.state.userDataFields.isCompanyAccount}
                              />
                            </Grid>
                          )}
                          {/*countryCode === "US" &&
                          (<Grid item xs={12}>
                            <Autocomplete
                              id="State"
                              required
                              options={stateList}
                              getOptionLabel={(option) => `${option?.name}`}
                              onChange={onStateChange}
                              defaultValue={states}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  helperText={stateError}
                                  label="State *"
                                  name="statecode"
                                  variant="outlined"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </Grid>)*/}
                          <Grid item xs={12}>
                            <TextField
                              value={streetAddressLn1}
                              onChange={(e) => {
                                setStreetAddressLn1(e.target.value);
                                setIsDataFieldsDirty(true);
                              }}
                              fullWidth
                              placeholder=""
                              label="Address (line 1)"
                              variant="outlined"
                              inputProps={{
                                'aria-label': 'streetAddressLn1',
                                'data-id': 'streetAddressLn1',
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              value={streetAddressLn2}
                              onChange={(e) => {
                                setStreetAddressLn2(e.target.value);
                                setIsDataFieldsDirty(true);
                              }}
                              fullWidth
                              placeholder=""
                              label="Address (line 2)"
                              variant="outlined"
                              inputProps={{
                                'aria-label': 'streetAddressLn2',
                                'data-id': 'streetAddressLn2',
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <USCityField
                              fullWidth
                              countryState={state}
                              onChange={onCityChange}
                              value={city}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextField
                              value={zipCode}
                              onChange={(e) => {
                                setZipCode(e.target.value);
                                setIsDataFieldsDirty(true);
                              }}
                              fullWidth
                              label="ZIP Code"
                              variant="outlined"
                              inputProps={{
                                'aria-label': 'zipCode',
                                'data-id': 'zipCode',
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ marginBottom: '10px' }}>
                            <TimezoneSelect
                              fullWidth
                              value={timezone}
                              accessToken={state.user.accessToken}
                              timezone={timezone}
                              setTimezone={setTimezone}
                              error={touched['timezone'] && !timezone?.trim()}
                              helperText={
                                touched['timezone'] &&
                                !timezone?.trim() &&
                                !timezone?.trim() &&
                                'This field is required'
                              }
                              onBlur={(e) => {
                                setTouched({ ...touched, timezone: true });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 'bold',
                          paddingBottom: '16px',
                          padding: '0 0 16px 0',
                        }}
                      >
                        Account data
                      </Typography>
                      <CardContent style={{ padding: 0 }}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <FormControlLabel
                              value="end"
                              disabled
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={isDeveloper}
                                  disabled
                                  onChange={() => {
                                    setIsDeveloper(!isDeveloper);
                                    setIsDataFieldsDirty(true);
                                  }}
                                  inputProps={{
                                    'aria-label': 'isDeveloper',
                                    'data-id': 'isDeveloper',
                                  }}
                                />
                              }
                              label="Developer features"
                              labelPlacement="end"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControlLabel
                              value="end"
                              control={
                                <Checkbox
                                  checked={isCompanyAccount}
                                  onChange={() => {
                                    setIsCompanyAccount(!isCompanyAccount);
                                    setIsDataFieldsDirty(true);
                                  }}
                                  color="primary"
                                  inputProps={{
                                    'aria-label': 'isCompanyAccount',
                                    'data-id': 'isCompanyAccount',
                                  }}
                                />
                              }
                              label="This is a company account"
                              labelPlacement="end"
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: 0 }}>
                          <Grid item xs={12}>
                            <TextField
                              value={accountName}
                              onChange={(e) => {
                                setAccountName(e.target.value);
                                setIsDataFieldsDirty(true);
                              }}
                              fullWidth
                              placeholder=""
                              disabled={!isCompanyAccount}
                              label="Company name"
                              variant="outlined"
                              inputProps={{
                                'aria-label': 'accountName',
                                'data-id': 'accountName',
                              }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <EmailField
                              value={accountEmailAddress}
                              onChange={(e) => {
                                setAccountEmailAddress(e.target.value);
                                setIsDataFieldsDirty(true);
                              }}
                              disabled={!isCompanyAccount}
                              fullWidth
                              label="Company email"
                              inputProps={{
                                'aria-label': 'accountEmailAddress',
                                'data-id': 'accountEmailAddress',
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              value={taxId}
                              onChange={(e) => {
                                setTaxId(e.target.value);
                                setIsDataFieldsDirty(true);
                              }}
                              fullWidth
                              placeholder=""
                              disabled={!isCompanyAccount}
                              label="Tax ID"
                              variant="outlined"
                              inputProps={{
                                'aria-label': 'taxid',
                                'data-id': 'taxId',
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {countryCode && countryCode.toUpperCase()}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Grid>
                  </Grid>
                  <Divider />
                  <form onSubmit={handleSaveData}>
                    <div style={{ paddingTop: '10px' }}>
                      <Button
                        sx={{ textTransform: 'none' }}
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ float: 'right' }}
                        disabled={
                          !givenName ||
                          !familyName ||
                          !countryCode ||
                          (countryCode === 'US' && !states) ||
                          !timezone
                        }
                      >
                        Save
                      </Button>
                    </div>
                  </form>
                </CardContent>
              </Card>
            </Grid>

            {isAccount && (
              <Grid item xs={12}>
                <Card>
                  <CardHeader title="Change Password" variant="h4" />
                  <CardContent>
                    <Typography>Here you change your password.</Typography>
                    <div style={{ textAlign: 'right' }}>
                      <Link
                        to={`${process.env.REACT_APP_ROOT_PATH}/profile/changepassword`}
                      >
                        <Button
                          // href='/profile/data-usage-policy'
                          variant="contained"
                          color="primary"
                          style={{ textTransform: 'capitalize' }}
                        >
                          Change Password
                        </Button>
                      </Link>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid item xs={12}>
              <Card>
                <CardHeader title="Privacy settings" variant="h4" />
                <CardContent>
                  <Typography>
                    Here you can check your current privacy settings.
                  </Typography>
                  <div style={{ textAlign: 'right' }}>
                    <Link
                      to={`${process.env.REACT_APP_ROOT_PATH}/profile/data-usage-policy`}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ textTransform: 'none' }}
                      >
                        Go
                      </Button>
                    </Link>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardHeader title="Delete account" />
                <CardContent>
                  <p>
                    <Typography style={{ color: 'red', fontSize: '14px' }}>
                      Once you delete your account, all your personal
                      information and didimos will be wiped, according to our{' '}
                      <a href="https://privacy.mydidimo.com/privacy-policy/">
                        Privacy Policy
                      </a>{' '}
                      and there is no going back.
                    </Typography>
                    <div style={{ textAlign: 'right' }}>
                      <Button
                        style={{
                          textTransform: 'capitalize',
                          backgroundColor: '#f44336',
                          fontWeight: '600',
                        }}
                        variant="contained"
                        color="primary"
                        onClick={() => setDeleteAccount(true)}
                      >
                        Delete your account
                      </Button>
                    </div>
                  </p>
                  <form>
                    <div></div>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <CookieBase></CookieBase>

          <ConfirmDeleteDialog
            title="Delete account"
            message={
              <div className="delete_accou">
                <p>This action will delete your account.</p>
                <p>
                  Once you delete your account, all your personal information
                  and didimos will be wiped, according to our{' '}
                  <a href="https://privacy.mydidimo.com/privacy-policy/">
                    Privacy Policy
                  </a>{' '}
                  and there is no going back.
                </p>
                <p>Are you sure you want to continue?</p>
              </div>
            }
            open={deleteAccount}
            onContinue={() => deleteAndSignOut()}
            onCancel={() => setDeleteAccount(false)}
            onClose={() => setDeleteAccount(false)}
          />
        </div>
      )}
      <SuccessSnack
        open={successMessageOpen}
        onClose={(e) => {
          setSuccessMessageOpen(false);
        }}
        message={'Your user data was updated!'}
      />
    </div>
  );
};

export default Settings;
