import React, { useState, useContext, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  TextField,
  Link,
  Typography,
  Alert,
  Grid,
  FormControlLabel,
  InputAdornment,
} from '@material-ui/core';
import TopLevelPanel from '../../components/TopLevelPanel';
import makeStyles from '@material-ui/styles/makeStyles';
import { GlobalContext } from '../../context/store';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  isUserAuthenticated,
  gotoUserAuthenticatedPage,
} from 'src/context/actions';
import { Card } from '@mui/material';

const useStyles = makeStyles({
  topLevel: {
    padding: '20px',
    marginTop: '88px;',
  },
  actionButton: {
    textAlign: 'right',
    paddingTop: 10,
  },
  fieldSet: {
    marginTop: '15px',
    marginBottom: '5px',
  },
});

const styles = {
  SummeryText: {
    width: '10%',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
  },
  summeryTitle: {
    color: '#2A0651',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  customCheckBox: {
    padding: '0',
    color: '#2A0651',
  },
};

const ProfileSetup = () => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [loadData, setLoadData] = useState(false);
  const [countries, setcountries] = useState(0);
  const [timeZonesData, setTimeZonesData] = useState(0);
  const [policies, setPolicies] = useState(0);
  const [stateData, setStateData] = useState(0);
  const [usState, setusState] = useState('');
  const [stateError, setStateError] = useState('');
  const [accessToken, setaccessToken] = useState('');
  const [profileID, setProfileID] = useState('');
  const [accountID, setAccountID] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [showPage, setShowPage] = useState(false);
  const [expanded, setExpanded] = React.useState('');
  const [termsData, setTermsData] = useState([]);

  const [termsRs, setTermsRs] = useState([]);

  const [seletectTerm, setseletectTerm] = useState([]);

  const { state, dispatch } = useContext(GlobalContext);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ROOT}data_usage_policies`)
      .then((response) => response.json())
      .then((data) =>
        setTermsData(
          data
          /*data.filter((item) => {
            return item.target_users.indexOf('users') > -1;
          })*/
        )
      );
  }, []);

  useEffect(() => {

    (async () => {
      try {
        const data = await Auth.currentSession();

        const decodeTokens = JSON.parse(
          atob(data.getIdToken().jwtToken.split('.')[1])
        );

        if (decodeTokens['custom:didimo_profile_uuid'] == undefined) {
          setLoadData(true);
        } else {
          const resPro = await axios.get(`profiles/me`, {});
          setTermsRs(resPro);

          var i;
          let myPolicy = [];
          let IsProfileOK = true;

          //setup whats been ticked
          for (i = 0; i < resPro?.data?.accounts[0]?.policies.length; i++) {
            if (
              resPro?.data?.accounts[0]?.policies[
                i
              ].data_policy.target_users.indexOf('users') > -1
            ) {
              if (resPro?.data?.accounts[0]?.policies[i]?.is_acceptance_required===true )
              {
              if (resPro?.data?.accounts[0]?.policies[i]?.status != undefined) {
                if (resPro?.data?.accounts[0]?.policies[i]?.status.length > 0) {
                  if (
                    resPro?.data?.accounts[0]?.policies[i]?.status !=
                      'accepted' &&
                    resPro?.data?.accounts[0]?.policies[i]?.data_policy
                      .is_acceptance_required
                  ) {
                    IsProfileOK = false;
                  }
                }
              } else {
                IsProfileOK = false;
              }
            }else
            {
              IsProfileOK = true;
            }

              myPolicy.push({
                data_policy_uuid:
                  resPro?.data?.accounts[0]?.policies[i]?.data_policy?.uuid,
                status:
                  resPro?.data?.accounts[0]?.policies[i]?.status != undefined
                    ? resPro?.data?.accounts[0]?.policies[i]?.status
                    : 'false',
              });
            } else {
              myPolicy.push({
                data_policy_uuid:
                  resPro?.data?.accounts[0]?.policies[i]?.data_policy?.uuid,
                status: resPro?.data?.accounts[0]?.policies[i]?.status,
              });
            }
          }



          //social login


        const setUpKey = localStorage.getItem('setUpKey');
        if (
          localStorage.getItem('isSignInWithKey') === '1' &&
          localStorage.getItem('setUpKey') !== null
        ) {
            await axios.post(
              'didimos/actions/import',
              {
                didimos_to_import: setUpKey,
              },
              {}
            );

            localStorage.removeItem('isSignInWithKey');
            localStorage.removeItem('setUpKey');
        }


          //social login






          if (IsProfileOK === true) {
            dispatch(gotoUserAuthenticatedPage(navigate, 'mydidimos'));
            // navigate(`${process.env.REACT_APP_ROOT_PATH}/mydidimos`);
          } else {
            setseletectTerm(myPolicy);
            setLoadData(true);
          }
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  async function signUp(values) {
    try {
      const user = await Auth.currentAuthenticatedUser();

      if (user) {
        const dataTerms = await fetch(
          `${process.env.REACT_APP_API_ROOT}data_usage_policies`
        );
        const jsonDataTerms = await dataTerms.json();

        let payloadItem = [];
        Object.keys(seletectTerm).forEach((key) => {
          payloadItem.push({
            data_policy_uuid: seletectTerm[key].data_policy_uuid,
            status: seletectTerm[key].status,
          });
        });

        //Can only post if account not exists
        const setUpKey = localStorage.getItem('setUpKey');
        if (
          localStorage.getItem('isSignInWithKey') === '1' &&
          localStorage.getItem('setUpKey') !== null
        ) {

          const res = await axios
          .post('accounts', { policies: payloadItem, is_developer: false , didimos_to_import: setUpKey}, {})
          .then((response) => {
            (async () => {
              try {
                dispatch(
                  await gotoUserAuthenticatedPage(navigate, 'mydidimos')
                );


                localStorage.removeItem('isSignInWithKey');
                localStorage.removeItem('setUpKey');

              } catch (err) {
                console.log(err);
              }
            })();
          })
          .catch((error) => {
            let ShouldRedirect = false;
            let payload = [
              {
                op: 'replace',
                path: '/policies',
                value: payloadItem,
              },
            ];

            try {
              axios
                .patch('accounts/default' /* + myAccountUuid*/, payload, {})
                .then((response) => {
                  for (let i = 0; i < response?.data?.policies?.length; i++) {
                    if (
                      response.data?.policies[i].data_policy
                        .is_acceptance_required === true &&
                      response.data?.policies[i].data_policy.is_withdrawable ===
                        true
                    ) {
                      if (response.data?.policies[i].status !== 'rejected') {
                        ShouldRedirect = true;
                      }
                    } else {
                      ShouldRedirect = true;
                    }
                  }

                  if (ShouldRedirect === true) {
                    (async () => {
                      try {
                        dispatch(
                          await gotoUserAuthenticatedPage(navigate, 'mydidimos')
                        );
                      } catch (err) {
                        console.log(err);
                      }
                    })();
                  }
                });
            } catch (err) {
              console.log('DataPolicy:', err);
            }
          });


        }else
        {

          const res = await axios
          .post('accounts', { policies: payloadItem, is_developer: false }, {})
          .then((response) => {
            (async () => {
              try {
                dispatch(
                  await gotoUserAuthenticatedPage(navigate, 'mydidimos')
                );
              } catch (err) {
                console.log(err);
              }
            })();
          })
          .catch((error) => {
            let ShouldRedirect = false;
            let payload = [
              {
                op: 'replace',
                path: '/policies',
                value: payloadItem,
              },
            ];

            try {
              axios
                .patch('accounts/default' /* + myAccountUuid*/, payload, {})
                .then((response) => {
                  for (let i = 0; i < response?.data?.policies?.length; i++) {
                    if (
                      response.data?.policies[i].data_policy
                        .is_acceptance_required === true &&
                      response.data?.policies[i].data_policy.is_withdrawable ===
                        true
                    ) {
                      if (response.data?.policies[i].status !== 'rejected') {
                        ShouldRedirect = true;
                      }
                    } else {
                      ShouldRedirect = true;
                    }
                  }

                  if (ShouldRedirect === true) {
                    (async () => {
                      try {
                        dispatch(
                          await gotoUserAuthenticatedPage(navigate, 'mydidimos')
                        );
                      } catch (err) {
                        console.log(err);
                      }
                    })();
                  }
                });
            } catch (err) {
              console.log('DataPolicy:', err);
            }
          });


        }






      }
    } catch (err) {
      setError(err);
    }
  }

  const handleChangeAccordian = (panel) => {
    if (expanded === panel) {
      setExpanded('');
    } else {
      setExpanded(panel);
    }
  };

  const onChangeOption = (e, key) => {
    let myArray = [...seletectTerm];

    //remove the item
    var i;
    for (i = 0; i < myArray.length; i++) {
      if (myArray[i].data_policy_uuid === key) {
        myArray.splice(i, 1);
      }
    }

    //add new item
    myArray.push({
      data_policy_uuid: key,
      status: e.target.checked === true ? 'accepted' : false,
    });

    setseletectTerm(myArray);
  };

  return (
    <>
      <Helmet>
        <title>Register | Didimo</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="xs" className={'signup-container'}>
          <Formik
            initialValues={{}}
            validationSchema={Yup.object().shape({})}
            validate={(values) => {
              const errors = {};
              let errorIdx = 0;
              let i;

              for (i = 0; i < seletectTerm.length; i++) {
                if (seletectTerm[i].status === 'accepted') {
                } else {
                  for (
                    let ix = 0;
                    ix < termsRs?.data?.accounts[0].policies?.length;
                    ix++
                  ) {
                    if (
                      termsRs?.data?.accounts[0].policies[ix].data_policy
                        .uuid == seletectTerm[i].data_policy_uuid
                    ) {
                      if (
                        termsRs?.data?.accounts[0].policies[ix]
                          .is_acceptance_required === true
                      ) {
                        if (
                          termsRs?.data?.accounts[0].policies[ix].data_policy
                            .type != 'information'
                        ) {
                          errors.policy = 'All fields must be checked';
                        }
                      }
                    }
                  }
                }
              }

              return errors;
            }}
            onSubmit={signUp}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                {loadData && (
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="h2" className={'main-header'}>
                      Confirm Terms
                    </Typography>
                  </Box>
                )}

                <Box
                  sx={{ alignItems: 'center', display: 'flex', ml: -1, mt: 3 }}
                >
                  <Typography color="textSecondary" variant="body1">
                    {loadData &&
                      termsData.map((terms, index) => {
                        const panel = `panel${index + 1}`;
                        return (
                          <Accordion expanded={expanded === panel}>
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon
                                  onClick={() => handleChangeAccordian(panel)}
                                />
                              }
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <Typography sx={styles.SummeryText}>
                                {terms.type != 'information' && (
                                  <Checkbox
                                    style={styles.customCheckBox}
                                    name={panel}
                                    disabled={
                                      terms.is_acceptance_required &&
                                      seletectTerm.find(
                                        (t) => t.data_policy_uuid === terms.uuid
                                      )?.status === 'accepted' &&
                                      !terms.is_withdrawable
                                    }
                                    checked={
                                      seletectTerm != null &&
                                      seletectTerm.length > 0 &&
                                      seletectTerm.find(
                                        (t) => t.data_policy_uuid === terms.uuid
                                      )?.status ===
                                        'accepted' /* (() => {
                                      if (
                                        seletectTerm.length > 0 &&
                                        seletectTerm.length - 1 >= index &&
                                        seletectTerm != null
                                      ) {
                                        return seletectTerm[index].status ===
                                          'accepted'
                                          ? true
                                          : false;
                                      } else {
                                        return false;
                                      }
                                    })()*/
                                    }
                                    value={(() => {})()}
                                    onChange={(e) => {
                                      onChangeOption(e, terms.uuid);
                                    }}
                                  />
                                )}
                              </Typography>

                              <Typography
                                sx={styles.summeryTitle}
                                onClick={() => handleChangeAccordian(panel)}
                              >
                                {terms?.title}{' '}
                                {terms?.is_acceptance_required ? '*' : ''}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{ borderBottom: '1px solid #2a0651' }}
                            >
                              <Typography>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: terms?.contents,
                                  }}
                                />
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                  </Typography>
                </Box>

                {Boolean(errors) && (
                  <FormHelperText error>{errors.policy}</FormHelperText>
                )}

                {loadData && (
                  <Box sx={{ py: 2 }} className={'SpinnerContainer'}>
                    <Button
                      disabled={
                        isSubmitting ||
                        termsData.filter(
                          (t) =>
                            t.is_acceptance_required &&
                            seletectTerm.find(
                              (st) => st.data_policy_uuid === t.uuid
                            )?.status !== 'accepted'
                        ).length > 0
                      }
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      className={'signIn-btn'}
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default ProfileSetup;
