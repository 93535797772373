import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Button,
  Hidden,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import ddmSample from '../../assets/Images/didimogroupshot.png';
import './Welcome.css';
import CookieBase from 'src/components/CookieBase';
import { GlobalContext } from 'src/context/store';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { getData, putData } from '../../helpers/dataFetching';
import { isUserAuthenticated } from 'src/context/actions';
import { Alert } from '@mui/material';

const Welcome = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);
  const [stopShowAtLogin, setStopShowAtLogin] = useState(false);
  const myLocPath = useLocation();

  useEffect(() => {
    (async () => {
      try {
        if (state.isAuth === false) {
          dispatch(await isUserAuthenticated(navigate));
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (state?.isAuth) {
      for (const idx in state?.user?.settings) {
        if (
          state.user.settings[idx].key ===
          'didimo.customerportal.is_welcome_screen_active'
        ) {
          setStopShowAtLogin(state.user.settings[idx].value);
          setIsLoading(false);
        }
      }
    }
  }, [state]);

  const handleCheckboxClick = async () => {
    try {
      let putBody = { value: !stopShowAtLogin };
      const res = await putData(
        'profiles/' +
          state?.user?.profile_uuid +
          '/settings/didimo.customerportal.is_welcome_screen_active',
        putBody
      );

      dispatch(await isUserAuthenticated(navigate, 'welcome'));
    } catch (error) {}
  };

  const [pendingOrders, setDdmoStatus] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData('accounts/default/status').then((res) => {
      setDdmoStatus(res?.pending_orders.length);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Welcome | Didimo</title>
      </Helmet>

      <Box
        sx={{
          px: 2,
          py: 2,
          backgroundColor: '#eeeee',
        }}
      >
        {pendingOrders > 0 && (
          <Alert
            severity="warning"
            style={{ border: '1px solid #ff9800', backgroundColor: 'white' }}
          >
            You have pending invoices. Please visit{' '}
            <Link to="/orders">Orders</Link> to proceed with the payment.
          </Alert>
        )}

        {isLoading == false && (
          <>
            <div className="welcome_content">
              <Container maxWidth="sm" className="container_main">
                <Box />
                <Grid
                  style={{
                    marginTop: '5px',
                    padding: '3rem',
                    fontWeight: 500,
                  }}
                >
                  <p
                    className="main_title"
                    style={{
                      fontSize: '4rem',
                      lineHeight: '4rem',
                      fontWeight: 700,
                      marginTop: 0,
                      marginBottom: 0,
                      color: '#2A0651',
                      fontFamily: 'sans-serif',
                    }}
                  >
                    Welcome
                  </p>
                  <p
                    className="sub_title"
                    style={{
                      fontSize: '2.5rem',
                      fontWeight: 700,
                      marginTop: 0,
                      marginBottom: 0,
                      color: '#2A0651',
                      fontFamily: 'sans-serif',
                    }}
                  >
                    to the Didimo Customer Portal
                  </p>
                </Grid>
                <Grid container>
                  <Grid
                    container
                    className="content"
                    item
                    xs={12}
                    lg={8}
                    padding={5}
                    style={{
                      minHeight: '500px',
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '0 3rem',
                      fontWeight: 500,
                      fontFamily: 'sans-serif',
                    }}
                  >
                    <Grid
                      item
                      style={{
                        fontSize: '1.2rem',
                      }}
                    >
                      <p>
                        We are excited to help you achieve your vision for
                        high-fidelity user-specific digital humans.
                      </p>
                      <p>
                        Via this portal, you can manage your account, including:
                      </p>
                      <ul
                        className="list_details"
                        style={{
                          marginTop: '1.5rem',
                          marginBottom: '3rem',
                          paddingInlineStart: '5rem',
                        }}
                      >
                        <li style={{ lineHeight: '2rem' }}>
                          Update your account settings
                        </li>
                        <li style={{ lineHeight: '2rem' }}>
                          Review{/*, manage*/} and download your didimos
                        </li>
                        {/*<li style={{ lineHeight: '2rem' }}>
                          Manage your applications and API keys
                        </li>
                        <li style={{ lineHeight: '2rem' }}>
                          Access the Didimo trial package
                        </li>
                        <li style={{ lineHeight: '2rem' }}>
                          Purchase or renew Access Packages
                        </li>*/}
                        <li style={{ lineHeight: '2rem' }}>
                          Review your transactions {/*&amp; orders*/}
                        </li>
                      </ul>
                      <p>
                        For question or technical support regarding anything in
                        this portal, please contact Didimo Support at:{' '}
                        <a href="mailto:support@didimo.co">support@didimo.co</a>
                      </p>
                    </Grid>
                  </Grid>

                  <Hidden lgDown>
                    <Grid item xs={12} lg={4}>
                      <Container>
                        <img
                          alt="Welcome"
                          src={ddmSample}
                          style={{
                            maxWidth: '100%',
                          }}
                        />
                      </Container>
                    </Grid>
                  </Hidden>
                </Grid>
                <Grid
                  className="check_bar"
                  item
                  container
                  style={{
                    marginTop: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    textAlign: 'right',
                  }}
                >
                  <Grid item xs={12}>
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          color="primary"
                          checked={stopShowAtLogin}
                          onChange={(e) => {
                            setStopShowAtLogin(e.target.checked);
                          }}
                          onClick={handleCheckboxClick}
                        />
                      }
                      label="Do not show this screen on login"
                      labelPlacement="end"
                    />
                    <Button
                      sx={{ textTransform: 'none' }}
                      color="primary"
                      variant="contained"
                    >
                      <Link style={{ color: 'white' }} to="/dashboard">
                        Go To Dashboard
                      </Link>
                    </Button>
                  </Grid>
                </Grid>
              </Container>

              <CookieBase></CookieBase>
            </div>
          </>
        )}
      </Box>
    </>
  );
};
export default Welcome;
