import {
  USER_SET_TOKEN,
  SAVE_USER_LOGIN,
  USER_LOGOUT,
  CHANGE_DEVELOPER,
  GET_DATA_AFTER_REFRESH,
  INITIAL_STATE,
  GOTO_USER_AUTHENTICATED_PAGE,
  SET_ERROR_MESSAGE,
  UPDATE_STATUS_MANAGER,
  INIT_STATUS_MANAGER,
  ADD_STATUS_MANAGER,
  REMOVE_STATUS_MANAGER,
  ADD_DOWNLOAD_STATUS_MANAGER,
  REMOVE_DOWNLOAD_STATUS_MANAGER,
  ADD_TO_CART,
  MARKET_PLACE,
} from './constants';
import { Auth } from 'aws-amplify';
import axios from 'axios';

export const setUserToken = (userToken) => {
  return {
    type: USER_SET_TOKEN,
    payload: userToken.accessToken,
  };
};

export const saveLoginUserData = (userData) => {
  return {
    type: SAVE_USER_LOGIN,
    payload: userData,
  };
};

export const filterModelData = (filterData) => {
  return {
    type: SAVE_USER_LOGIN,
    payload: filterData,
  };
};

export const changeDeveloper = (userData) => {
  return {
    type: CHANGE_DEVELOPER,
    payload: userData,
  };
};

export const userLogout = () => {
  localStorage.removeItem('firstTimeLogin');
  return {
    type: USER_LOGOUT,
  };
};

export const login = async (values, navigate) => {
  try {
    const user = await Auth.signIn(values.email, values.password);
    if (user) {
      const data = await Auth.currentSession();

      const decodeTokens = JSON.parse(
        atob(data.getIdToken().jwtToken.split('.')[1])
      );

      if (decodeTokens['custom:didimo_profile_uuid'] == undefined) {
        let payloadItem = [];
        let dataTerms = user.attributes['custom:terms']?.split(',') || [];

        Object.keys(dataTerms).forEach((key) => {
          payloadItem.push({
            data_policy_uuid: dataTerms[key],
            status: 'accepted',
          });
        });

        const setUpKey = localStorage.getItem('setUpKey');
        if (
          localStorage.getItem('isSignInWithKey') === '1' &&
          localStorage.getItem('setUpKey') !== null
        ) {
          await axios.post(
            'accounts',
            {
              policies: payloadItem,
              is_developer: false,
              didimos_to_import: setUpKey,
            },
            {}
          );
          localStorage.removeItem('isSignInWithKey');
          localStorage.removeItem('setUpKey');
        } else {
          await axios.post(
            'accounts',
            { policies: payloadItem, is_developer: false },
            {}
          );
        }
      }else
      {

        const setUpKey = localStorage.getItem('setUpKey');
        if (
          localStorage.getItem('isSignInWithKey') === '1' &&
          localStorage.getItem('setUpKey') !== null
        ) {
            await axios.post(
              'didimos/actions/import',
              {
                didimos_to_import: setUpKey,
              },
              {}
            );

            localStorage.removeItem('isSignInWithKey');
            localStorage.removeItem('setUpKey');

        }


      }

      try {
        const res = await axios.get(`profiles/me`, {
          headers: { Authorization: `Bearer ${data.accessToken.jwtToken}` },
        });
        const basketData = await axios.get(`billing/basket`);
        const notificationData = await axios.get(
          `accounts/${res.data.accounts[0].uuid}/notifications?page=1`
        );
        const marketPlaceData = await axios.get(
          `/billing/products?product_type=character_pack&product_type=xpto&active=true&purchasable=true`
        );

        const payload = {
          accessToken: user.signInUserSession.accessToken.jwtToken,
          email: res.data.email_address,
          family_name: res.data.family_name,
          given_name: res.data.given_name,
          accounts: res.data.accounts[0],
          profile_uuid: res.data.uuid,
          isDeveloper: res.data.accounts[0].is_developer,
          settings: res.data.settings,
          cartData: basketData.data.accountProductsBasket,
          marketPlaceData: marketPlaceData.data,
          notificationData: notificationData.data,
        };
        localStorage.setItem('firstTimeLogin', 1);
        return {
          type: SAVE_USER_LOGIN,
          payload,
        };
      } catch (error) {
        return {
          type: SET_ERROR_MESSAGE,
          payload: error,
        };
      }
    }
  } catch (error) {
    //console.log(error);
    return {
      type: SET_ERROR_MESSAGE,
      payload: error,
    };
  }
};

export const isUserAuthenticated = async (navigate, redirect_uri) => {
  try {
    const data = await Auth.currentAuthenticatedUser();

    if (data?.username) {
      const dataCurrentUser = await Auth.currentSession();
      const decodeTokens = JSON.parse(
        atob(dataCurrentUser.getIdToken().jwtToken.split('.')[1])
      );

    //claim didimo
      const setUpKey = localStorage.getItem('setUpKey');
      if (
        localStorage.getItem('isSignInWithKey') === '1' &&
        localStorage.getItem('setUpKey') !== null
      ) {
          await axios.post(
            'didimos/actions/import',
            {
              didimos_to_import: setUpKey,
            },
            {}
          );

          localStorage.removeItem('isSignInWithKey');
          localStorage.removeItem('setUpKey');

      }
      //claim didimo




      //if logged in and 401 error then its first time
      const res = await axios.get(`profiles/me`);
      const basketData = await axios.get(`billing/basket`);
      const notificationData = await axios.get(
        `accounts/${res.data.accounts[0].uuid}/notifications?page=1`
      );
      const marketPlaceData = await axios.get(
        `/billing/products?product_type=character_pack&product_type=xpto&active=true&purchasable=true`
      );

      const resPro = res;
      let i;
      let IsProfileOK = true;
      let myPolicy = [];

      // check poclicy status
      for (i = 0; i < resPro?.data?.accounts[0]?.policies.length - 1; i++) {
        if (
          resPro?.data?.accounts[0]?.policies[
            i
          ].data_policy.target_users.indexOf('users') > -1
        ) {
          if (resPro?.data?.accounts[0]?.policies[i]?.status != undefined) {
            if (resPro?.data?.accounts[0]?.policies[i]?.status.length > 0) {
              if (
                resPro?.data?.accounts[0]?.policies[i]?.status !== 'accepted' &&
                resPro?.data?.accounts[0]?.policies[i]?.data_policy
                  .is_acceptance_required
              ) {
                IsProfileOK = false;
              }
            }
          } else if (
            resPro?.data?.accounts[0]?.policies[i]?.data_policy
              .is_acceptance_required
          ) {
            IsProfileOK = false;
          }

          myPolicy.push({
            data_policy_uuid:
              resPro?.data?.accounts[0]?.policies[i]?.data_policy?.uuid,
            status:
              resPro?.data?.accounts[0]?.policies[i]?.status != undefined
                ? resPro?.data?.accounts[0]?.policies[i]?.status
                : 'false',
          });
        } else {
          myPolicy.push({
            data_policy_uuid:
              resPro?.data?.accounts[0]?.policies[i]?.data_policy?.uuid,
            status: resPro?.data?.accounts[0]?.policies[i]?.status,
          });
        }
      }

      if (!IsProfileOK) {
        navigate(`${process.env.REACT_APP_ROOT_PATH}/profile/login/`);
        return {
          type: INITIAL_STATE,
        };
      }

      const payload = {
        accessToken: dataCurrentUser.accessToken.jwtToken,
        email: res.data.email_address,
        family_name: res.data.family_name,
        given_name: res.data.given_name,
        accounts: res.data.accounts[0],
        profile_uuid: res.data.uuid,
        isDeveloper: res.data.accounts[0].is_developer,
        settings: res.data.settings,
        cartData: basketData.data.accountProductsBasket,
        marketPlaceData: marketPlaceData.data,
        notificationData: notificationData.data,
      };
      return {
        type: SAVE_USER_LOGIN,
        payload,
      };
    } else {
      return {
        type: INITIAL_STATE,
      };
    }
  } catch (e) {
    const redirectTo = redirect_uri
      ? `login#redirect_uri=${redirect_uri}`
      : 'login';
    navigate(`${process.env.REACT_APP_ROOT_PATH}/${redirectTo}`, {
      replace: true,
    });
    return {
      type: INITIAL_STATE,
    };
  }
};

export const gotoUserAuthenticatedPage = async (navigate, redirect_uri) => {
  try {
    const data = await Auth.currentAuthenticatedUser();

    if (data?.username) {
      const dataCurrentUser = await Auth.currentSession();
      //if logged in and 401 error then its first time
      const res = await axios.get(`profiles/me`);

      navigate(`${process.env.REACT_APP_ROOT_PATH}/${redirectTo}`, {
        replace: true,
      });
      const payload = {
        accessToken: dataCurrentUser.accessToken.jwtToken,
        email: res.data.email_address,
        family_name: res.data.family_name,
        given_name: res.data.given_name,
        accounts: res.data.accounts[0],
        profile_uuid: res.data.uuid,
        isDeveloper: res.data.accounts[0].is_developer,
        settings: res.data.settings,
      };
      return {
        type: GOTO_USER_AUTHENTICATED_PAGE,
        payload,
      };
    } else {
      return {
        type: INITIAL_STATE,
      };
    }
  } catch (e) {
    const redirectTo = redirect_uri
      ? `login#redirect_uri=${redirect_uri}`
      : 'login';
    navigate(`${process.env.REACT_APP_ROOT_PATH}/${redirectTo}`, {
      replace: true,
    });
    return {
      type: INITIAL_STATE,
    };
  }
};

export const initStatusManager = (data) => {
  return {
    type: INIT_STATUS_MANAGER,
    payload: data,
  };
};

export const updateStatusManager = (data) => {
  return {
    type: UPDATE_STATUS_MANAGER,
    payload: data,
  };
};

export const addStatusManager = (data = []) => {
  return {
    type: ADD_STATUS_MANAGER,
    payload: data.didimos.map((ddmo) => ({
      id: ddmo.key,
      type: 'generating',
      percent: ddmo.percent,
      status: ddmo.status,
      updatedAt: new Date(),
    })),
  };
};

export const addDownloadStatusManager = (data) => {
  return {
    type: ADD_DOWNLOAD_STATUS_MANAGER,
    payload: data,
  };
};

export const removeStatusManager = (data) => {
  return {
    type: REMOVE_STATUS_MANAGER,
    payload: data,
  };
};

export const removeDownloadStatusManager = (data) => {
  return {
    type: REMOVE_DOWNLOAD_STATUS_MANAGER,
    payload: data,
  };
};

export const addToCart = (data) => {
  return {
    type: ADD_TO_CART,
    payload: data,
  };
};

export const marketPlace = (data) => {
  return {
    type: MARKET_PLACE,
    payload: data,
  };
};
