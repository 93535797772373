import React, { Fragment } from 'react';
import { TableContainer, Grid, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import { v4 } from 'uuid';
import { formatCurrency, formatPoints } from '../../../helpers/formaters';
import TierIcon from '../../../components/TierIcon';
import { services, packages } from '../PackageData/PackageData';
import { postData } from '../../../helpers/dataFetching';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const PACKAGE_OPACITY = 0.3;
const styles = (theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  applicationDetail: {
    flexDirection: 'column',
  },
  deleteButton: {
    backgroundColor: 'red',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  backdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  icon: {
    height: 96,
    width: 96,
    objectFit: 'scale-down',
  },
  trialHeader: {
    backgroundColor: theme.packages.trial.mainColor,
  },
  basicHeader: {
    backgroundColor: theme.packages.basic.mainColor,
  },
  starterHeader: {
    backgroundColor: theme.packages.basic.mainColor,
  },
  advancedHeader: {
    backgroundColor: theme.packages.advanced.mainColor,
  },
  proHeader: {
    backgroundColor: theme.packages.pro.mainColor,
  },
  enterpriseHeader: {
    backgroundColor: theme.packages.enterprise.mainColor,
  },
  trial: {
    backgroundColor: theme.packages.trial.mainOpaqueColor(PACKAGE_OPACITY),
  },
  basic: {
    backgroundColor: theme.packages.basic.mainOpaqueColor(PACKAGE_OPACITY),
  },
  starter: {
    backgroundColor: theme.packages.basic.mainOpaqueColor(PACKAGE_OPACITY),
  },
  advanced: {
    backgroundColor: theme.packages.advanced.mainOpaqueColor(PACKAGE_OPACITY),
  },
  pro: {
    backgroundColor: theme.packages.pro.mainOpaqueColor(PACKAGE_OPACITY),
  },
  enterprise: {
    backgroundColor: theme.packages.enterprise.mainOpaqueColor(PACKAGE_OPACITY),
  },
  checkedFeature: {
    color: green[600],
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  points: {
    backgroundColor: 'rgba(0,0,0,.08)',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '15px',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    fontSize: '1em',
    '@media only screen and (max-width: 660px)': {
      borderRadius: '0',
      fontSize: '0.8em',
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
  packagesRoot: {
    display: 'grid',
    gridTemplateColumns: '300px repeat(3, minmax(0, 1fr))',
    '& > div': {
      padding: '16px',
      borderTop: '1px solid #eeeeee',
      borderBottom: '1px solid #eeeeee',
      verticalAlign: 'middle',
      fontSize: '14px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      lineHeight: '21px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '@media only screen and (max-width: 1024px)': {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      '& > div': {
        borderTop: '0',
        borderBottom: '0',
        fontSize: '13px',
      },
    },
    '@media only screen and (max-width: 660px)': {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      '& > div': {
        padding: '10px',
      },
    },
  },
  packageHeader: {
    textAlign: 'center',
    position: 'relative',
    '&[data-package-index="0"]': {
      gridColumn: '2',
    },
    '@media only screen and (max-width: 1024px)': {
      '&[data-package-index="0"]': {
        gridColumn: '1',
      },
    },
    '@media only screen and (max-width: 660px)': {
      '&:not(.selected)': {
        '& p': {
          fontSize: '12px',
          lineHeight: '12px',
        },
        '& img': {
          width: '48px',
          height: '48px',
        },
        padding: '8px',
        overflow: 'hidden',
      },
    },
    '@media only screen and (max-width: 320px)': {
      '&:not(.selected)': {
        '& p': {
          fontSize: '10px',
        },
      },
    },
  },
  attributeName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left !important',
    '@media only screen and (max-width: 1024px)': {
      gridColumn: '1/4',
      padding: '10px 10px 4px 10px !important',
      justifyContent: 'center !important',
      '& p': {
        fontWeight: 'bold',
        fontSize: '12px',
      },
      '& div': {
        textAlign: 'center',
      },
    },
  },
  attributeValue: {
    '@media only screen and (max-width: 660px)': {
      '&:not(.selected)': {
        /* display: "none !important", */
      },
    },
  },
  areaName: {
    gridColumn: '1/5',
    textAlign: 'center',
    fontWeight: 'bold',
    '@media only screen and (max-width: 1024px)': {
      gridColumn: '1/4',
      borderTop: '1px solid #eeeeee !important',
      marginTop: '16px !important',
      marginBottom: '8px !important',
      paddingTop: '8px !important',
      paddingBottom: '0 !important',
    },
  },
});

const CheckedIcon = withStyles(styles)((props) => (
  <CheckCircleIcon className={props.classes.checkedFeature} />
));

function PackageDetailsTable(props) {
  const navigate = useNavigate();

  const handleBuy = async (productId) => {
    const payload = { uuid: productId.uuid };
    const account = await axios.get(`accounts/default`);
    const missingPolicies = account.data.policies.filter(
      (policyMissing) =>
        (policyMissing.status !== 'accepted') &
        (policyMissing.data_policy.target_users.length === 1) &
        (policyMissing.data_policy.target_users[0] === 'customers')
    );
    if (
      missingPolicies.length > 0 ||
      !account.data.country ||
      (account.data.address.country === 'US' && !account.data.address.state) ||
      !account.data.owner_profile.given_name ||
      !account.data.owner_profile.family_name
    ) {
      navigate('/packages#selectNewPackage=true');
    } else {
      axios
        .post('services/payments/stripe/orders', payload)
        .then((response) => {
          if (response.data.paymentIntentId) {
            navigate(`/orders#checkout=${response.data.uuid}`);
          }
        });
    }
  };

  const actionArea = (
    <>
      {packages.map((pckg, index) => (
        <Grid
          key={v4()}
          align="center"
          className={`${props.classes.packageHeader} ${
            props.classes[`${pckg.code}Header`]
          }`}
          data-package-index={index}
        >
          {(pckg.action === 'register' && props.showRegister && (
            <Button href="/" size="small" color="secondary" variant="contained">
              Register
            </Button>
          )) ||
            (pckg.action === 'buy' && (
              <Button
                data-id={
                  (props.products.find((f) => f.name === pckg.name) || {}).uuid
                }
                size="small"
                color="secondary"
                variant="contained"
                style={{
                  padding: '4px 10px',
                  fontSize: '0.8125rem',
                  textTransform: 'capitalize',
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                }}
                onClick={() =>
                  handleBuy(props.products.find((f) => f.name === pckg.name))
                }
                //href={`${process.env.REACT_APP_ROOT_PATH}/packages#selectNewPackage=true`}
              >
                Buy
              </Button>
            ))}
        </Grid>
      ))}
    </>
  );

  return (
    <TableContainer
      style={{
        maxWidth: '1000px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Grid className={props.classes.packagesRoot}>
        {packages.map((pckg, index) => (
          <Grid
            key={v4()}
            align="center"
            className={`${props.classes.packageHeader} ${
              props.classes[`${pckg.code}Header`]
            }`}
            data-package-index={index}
          >
            <div style={{ display: 'block', width: '100%' }}>
              <TierIcon tierCode={pckg.code} className={props.classes.icon} />
              <Typography variant="h4" component="p">
                {pckg.name}
              </Typography>
            </div>
          </Grid>
        ))}

        {process.env.REACT_APP_ALLOW_BUY==="true" && actionArea}

        {services.map((service) => (
          <Fragment key={v4()}>
            {service.name && (
              <Grid className={props.classes.areaName}>{service.name}</Grid>
            )}
            {service.services.map((feature, index) => (
              <Fragment key={v4()}>
                <Grid component="div" className={props.classes.attributeName}>
                  <div>
                    <Typography variant="body1">{feature.name}</Typography>
                    {props.showAPIInfo &&
                      feature.apiActions &&
                      feature.apiActions.length > 0 &&
                      feature.apiActions.map((apiAction) => (
                        <Typography variant="caption" key={v4()}>
                          API: {apiAction.name}
                        </Typography>
                      ))}
                  </div>
                </Grid>
                {packages.map((pckg) => (
                  <Grid
                    key={v4()}
                    align="center"
                    className={`${props.classes[pckg.code]}
                      ${props.classes.attributeValue}`}
                  >
                    <div style={{ display: 'block', width: '100%' }}>
                      {(pckg.features[feature.code] &&
                        pckg.features[feature.code].price && (
                          <>
                            {formatCurrency(
                              pckg.features[feature.code].price,
                              0
                            )}
                            <Typography variant="body2">plus taxes</Typography>
                          </>
                        )) ||
                        ''}

                      {(pckg.features[feature.code] &&
                        pckg.features[feature.code].available === true && (
                          <CheckedIcon />
                        )) ||
                        ''}
                      {(pckg.features[feature.code] &&
                        pckg.features[feature.code].text &&
                        pckg.features[feature.code].text) ||
                        ''}
                      {(pckg.features[feature.code] &&
                        pckg.features[feature.code].cost && (
                          <Typography
                            variant="body2"
                            className={props.classes.points}
                          >
                            {formatPoints(pckg.features[feature.code].cost)}{' '}
                            points
                          </Typography>
                        )) ||
                        ''}
                    </div>
                  </Grid>
                ))}
              </Fragment>
            ))}
          </Fragment>
        ))}

        {actionArea}
      </Grid>
    </TableContainer>
  );
}

export default withStyles(styles)(PackageDetailsTable);
