import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import TierIcon from '../../../components/TierIcon';
import PackageCard from '../PackageCard/PackageCard';
import CurrentLinearProgress from '../../../components/packages/CurrentLinearProgress';
import { formatDateTime, formatPoints } from '../../../helpers/formaters';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CreateOrderPackageCard from '../PackageCard/CreateOrderPackageCard';
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  logo: {
    height: 64,
    width: 64,
    objectFit: 'scale-down',
  },
  topMenu: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },

  MobileShowcase: {
    backgroundColor: theme.packages.trial.mainColor,
  },
  trial: {
    backgroundColor: theme.packages.trial.mainColor,
  },
  basic: {
    backgroundColor: theme.packages.basic.mainColor,
  },
  starter: {
    backgroundColor: theme.packages.basic.mainColor,
  },
  advanced: {
    backgroundColor: theme.packages.advanced.mainColor,
  },
  pro: {
    backgroundColor: theme.packages.pro.mainColor,
  },
  enterprise: {
    backgroundColor: theme.packages.enterprise.mainColor,
  },
}));

export default function PackageTable({
  bucketData: rows,
  currentTier,
  className,
  productData,
  onBuy,
}) {
  const classes = useStyles();
  let navigate = useNavigate();

  function HandleDetailsBtn() {
    navigate('/packages/details', { replace: true });
  }

  return (
    <>
      <Helmet>
        <title>Packages | Didimo</title>
      </Helmet>

      <Grid
        container
        spacing={4}
        alignItems="stretch"
        alignContent="stretch"
        justify="space-evenly"
      >
        {process.env.REACT_APP_DISPLAY_PACKAGES==="true" && <Grid item xs={12}>
          <Card className={className}>
            <CardHeader
              action={
                <Button
                  onClick={HandleDetailsBtn}
                  size="small"
                  variant="outlined"
                  sx={{ textTransform: 'none' }}
                >
                  Details
                </Button>
              }
              title="Available packages"
            />
            <CardContent>
              <Grid container spacing={2} direction="row" alignItems="center">
                {productData
                  .filter((product) => product.price > 0)
                  .map((product) => (
                    <React.Fragment key={product.uuid}>
                      <Grid item xs>
                        <PackageCard
                          title={product.name}
                          tierCode={product.name}
                          productId={product.uuid}
                          isBuyable
                          isActive={product.current_product_price.active}
                          current_price={product.current_product_price.price}
                          price={product.price}
                          points={product.points}
                          onBuy={onBuy}
                          // description={product.shortDescription}
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>}
        <Grid item xs>
          <Card>
            <CardContent
              className={classes[currentTier.tier && currentTier.tier.code]}
            >
              <Typography
                variant="h5"
                style={{
                  textAlign: 'center',
                }}
              >
                Your current tier level is{' '}
                <strong>{currentTier.tier && currentTier.tier.name}</strong>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={className}>
            <CardHeader title="Active packages" />
            <CardContent>
              {rows.packages.map((packageItem, index) => (
                <React.Fragment key={packageItem.product.uuid}>
                  {index > 0 && <Divider />}
                  <Grid container style={{ margin: '10px 0' }}>
                    <Grid item>
                      <TierIcon
                        tierCode={packageItem.product.name}
                        className={classes.logo}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      lg={8}
                      direction="column"
                      justify="space-between"
                      container
                      style={{
                        flexGrow: 1,
                        width: 'auto',
                        marginRight: '15px',
                      }}
                    >
                      <Grid container justify="space-between" item>
                        <Grid item>
                          <Typography variant="h3" component="h2">
                            {packageItem.product.name}
                          </Typography>
                          <Typography variant="body2">
                            subscribed on{' '}
                            {formatDateTime(packageItem.created_at)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          {packageItem.expiresOn && (
                            <Typography variant="body2">
                              expires on{' '}
                              {formatDateTime(packageItem.expires_at)}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item>
                        <CurrentLinearProgress
                          value={
                            (packageItem.balance / packageItem.initial_value) *
                            100
                          }
                          variant="determinate"
                          style={{ marginBottom: '5px' }}
                          activePackage={packageItem}
                        />
                      </Grid>
                    </Grid>
                    <Grid item style={{ marginLeft: 'auto', minWidth: '5em' }}>
                      <Typography variant="h4" align="right">
                        {formatPoints(packageItem.balance)}
                      </Typography>
                      <Typography variant="body2" align="right">
                        points left
                      </Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
